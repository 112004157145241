<template>
  <div class="batch-details">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Banner
        heading="Batch Details"
        subtitle="View Batch Details"
        icon="mdi-text-box-multiple-outline"
        iconSize="xxx-large"
      />
    </v-container>
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <div class="d-flex justify-space-between px-4">
        <v-btn
          class="mb-4"
          color="primary"
          large
          @click="updateBatch"
        >
          <v-icon left>mdi-refresh</v-icon>
          Refresh Counts
        </v-btn>
        <v-btn
          color="primary"
          large
          @click="goBack"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </div>
      <v-container
        fluid
        class="pl-0 pr-0"
      >
        <v-card class="pa-6 my-2 mx-4">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Batch Name</th>
                  <th class="text-left">Submmitted By</th>
                  <th class="text-left">Total Subscriptions</th>
                  <th class="text-left">Approved</th>
                  <th class="text-left">Rejected</th>
                  <th class="text-left">Errored</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.batchName"
                >
                  <td>{{ item.batchName }}</td>
                  <td>{{ item.createdBy }}</td>
                  <td>{{ item.total }}</td>
                  <td>{{ item.approved }}</td>
                  <td>{{ item.rejected }}</td>
                  <td>{{ item.errored }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { Banner } from '@nswdoe/doe-ui-core'
const defaultDetails = {
  batchId: '',
  batchName: '',
  supplierId: '',
  total: '',
  submitted: '',
  approved: '',
  rejected: '',
  errored: '',
  initiated: '',
  unknown: '',
  notified: '',
  createdBy: '',
  lastModifiedBy: '',
  recordCreatedTime: '',
  recordLastModified: '',
}

export default {
  name: 'ViewBatchDetails',
  title: 'View Batch Details - SAIS Operation UI Portal',
  components: {
    Banner,
  },
  data() {
    return {
      items: [defaultDetails],
    }
  },
  async created() {
    this.fetch()
  },
  methods: {
    async fetch(shouldUpdate) {
      const payload = {
        batchId: this.$route.params?.batchId,
        supplierId: this.$route.query.supplierId,
        batchName: this.$route.query.batchName,
      }
      if (shouldUpdate) payload.update = true

      await this.$store.dispatch('moduleSubscriptionRequests/fetchBatchDetails', payload)
      const batch = this.$store.state.moduleSubscriptionRequests.batchDetails

      if (batch) {
        batch.recordCreatedTime = dayjs(batch.recordCreatedTime).format('YYYY-MM-DD HH:mm A')
        batch.recordLastModified = dayjs(batch.recordLastModified).format('YYYY-MM-DD HH:mm A')
      }

      this.items = batch ? [batch] : [defaultDetails]
    },
    goBack() {
      this.$router.go(-1)
    },
    updateBatch() {
      this.fetch(true)
    },
  },
}
</script>

<style scoped>
.section-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subsection-heading {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.field-label {
  font-weight: bold;
}
</style>
